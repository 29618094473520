import React, { lazy } from "react";

import { Routes, Route } from "react-router-dom";

const ProducaoCadastros = lazy(() => import('../pages/Producao/Cadastros/index'));
const Produzir = lazy(() => import('../pages/Producao/Produzir/index'));
const ProducaoAcompanhamento = lazy(() => import('../pages/Producao/Produzir/ProducaoAcompanhamento'));
const Processo = lazy(() => import('../pages/Producao/ProcessoRealizados'));

export default function ProducaoRoutes() {
    return (
        <Routes>
            <Route path="/producao" exact element={<ProducaoCadastros />} />
            <Route path="/produzir" exact element={<Produzir />} />
            <Route path="/produzir/:processoId/" exact element={<ProducaoAcompanhamento />} />
            <Route path="/produzir/:processoId/:origem" exact element={<ProducaoAcompanhamento />} />
            <Route path="/processo" exact element={<Processo />} />
        </Routes>
    )
}
import React, { useState, useEffect } from 'react';

import './style.scss';

import { PanelMenu } from 'primereact/panelmenu';
import { BreadCrumb } from 'primereact/breadcrumb';

import cadastroMenuItens from './Menus/CadastroMenuItens';
import producaoMenuItens from './Menus/ProducaoMenuItens';
import laboratorioMenuItens from './Menus/LaboratorioMenuItens';

import { apiGet } from '../../config/api';
import { logout } from '../../config/login';

const homeMenu = { label: 'Inicio', icon: 'pi pi-home' };
const cadastroMenu = cadastroMenuItens();
const producaoMenu = producaoMenuItens();
const laboratorioMenu = laboratorioMenuItens();


const items = () => {

    const itemsPermit = [];
    /**
     * 
     *  ==>> SEMPRE QUE CRIAR UM NOVO MENU ADICIONAR AQUI <<==
     * 
     */

    if (cadastroMenu) {
        itemsPermit.push(cadastroMenu);
    }
    if (producaoMenu) {
        itemsPermit.push(producaoMenu);
    }
    if (laboratorioMenu) {
        itemsPermit.push(laboratorioMenu);
    }

    const items = itemsPermit.sort((a, b) => a.label.localeCompare(b.label));
    return items;

}

const menuItens = items();

const LayoutMenu = (props) => {

    const usuario = localStorage.getItem('usuario');

    const [breadCrumbItems, setBreadCrumbItems] = useState([]);

    useEffect(() => {
        createBreadCrumb();
        checkSession();
    }, []);


    const checkSession = () => {
        apiGet('/token');
    }

    const createBreadCrumb = () => {

        let breadCrumbItems = [];

        menuItens.forEach(mi => {

            const pathMenuName = mi.label;

            if (mi.items) {
                mi.items.forEach(mii => {
                    if (window.location.pathname.includes(mii.url)) {
                        // setPageTitle(mii.label)
                        breadCrumbItems.push({ label: pathMenuName });
                        breadCrumbItems.push({ label: mii.label })
                        setBreadCrumbItems(breadCrumbItems);
                    }
                })
            }
        })


    }

    function openNav() {
        document.getElementById("rpmi-sidenav").style.width = "250px";
        document.getElementById("rpmi-sidenav").classList.add("rpmi-sidenav-shadow");
    }

    function closeNav() {
        document.getElementById("rpmi-sidenav").style.width = "0";
        document.getElementById("rpmi-sidenav").classList.remove("rpmi-sidenav-shadow");
    }

    return (
        <React.Fragment>

            <div className='rpmi-workspace'>
                <div className='rpmi-menu'>
                    <button className='rpmi-menu-btn' onClick={() => openNav()}>
                        <i className="fa-solid fa-bars" />
                    </button>
                    <button className='rpmi-logout-btn' onClick={() => logout()}>
                        <i className="fa-solid fa-power-off" style={{ paddingRight: '5px' }} />
                        Sair
                    </button>
                </div>

                <div id="rpmi-sidenav" className="rpmi-sidenav">
                    <button className="close-btn" onClick={() => closeNav()}>&times;</button>
                    <div className="loged-user ">
                        <i className="fa-solid fa-user" />
                        <label style={{ paddingLeft: '6px' }}>
                            {usuario ? usuario : 'Usuario'}
                        </label>
                    </div>
                    <div style={{ padding: '8px' }}>
                        <PanelMenu model={menuItens} />
                    </div>
                </div>

                <BreadCrumb model={breadCrumbItems} home={homeMenu} />

                <div className='rpmi-content'>
                    {props.children}
                </div>
            </div>

            <div className='rpmi-footer'>
                © {new Date().getFullYear()} RPMI System • Todos direitos reservados.
            </div>

        </React.Fragment >
    )

}

export default LayoutMenu;
import menuCreator from '../../Handlers/MenuCreatorHandler';

const itens = [
    { label: 'Recebimento Matéria Prima', icon: 'fas fa-glass-whiskey', auth: 'LABORATORIO_RECEBIMENTO_MATERIA_PRIMA', url: '/laboratorio/recebimentomateriaprima' },
]

const laboratorioMenuItens = () => {
    return menuCreator(itens, 'Laboratório', 'fas fa-flask')
}

export default laboratorioMenuItens;
import React from 'react';
import ReactDOM from 'react-dom/client';

import './App.scss';

import "primereact/resources/themes/lara-dark-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import 'primeflex/primeflex.css';

import ApplicationRoutes from './routes/root';
import Layout from './components/Layout';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Layout>
        <ApplicationRoutes></ApplicationRoutes>
    </Layout>
);


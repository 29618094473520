import React, { useState } from 'react';
import styled from 'styled-components'

import { login } from '../../config/login';

import background from "../../static/images/3185087.jpg";

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
    background-color: #013e35a6; //# 776bccab
`

const LoginBox = styled.div`
	background: linear-gradient(90deg,#05241a,#4FE8D0);
	position: relative;	
	height: 600px;
	width: 360px;	
	box-shadow: 0px 0px 24px #4b8a59;
`

const LoginForm = styled.div`
	z-index: 1;
	position: relative;	
	height: 100%;
`

const LoginFormInput = styled.form`
	padding: 30px;
	padding-top: 156px;
`

const LoginBackground = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	-webkit-clip-path: inset(0 0 0 0);
	clip-path: inset(0 0 0 0);	
`

const SystemName = styled.div`
    position: absolute;
    height: 140px;
    width: 175px;
    bottom: 0px;
    right: 0px;
    color: #fff;
    font-size: 1.4rem;
    font-weight: 600;
`

const LoginField = styled.div`
    padding: 10px 0px;	
	position: relative;	
`

const LoginIcon = styled.div`
	position: absolute;
	top: 25px;
	color: #424242;
    padding-left: 10px;
`

const LoginInput = styled.input`
	border: none;
	border-bottom: 2px solid #424242;
	background: none;
	padding: 10px;
	padding-left: 38px;
	font-weight: 700;
	width: 100%;
	transition: .2s;
    color: #424242;
    height: 3rem;
    font-size: 1rem;

    &:active, :hover, :focus {
        outline: none;
	    border-bottom-color: #6A679E;
    }

    ::placeholder {
        color: #424242;
    }

`

const ShapeOne = styled.span`
	transform: rotate(45deg);
	position: absolute;
    height: 520px;
	width: 520px;
	background: #c5f4ec;	
	top: -50px;
	right: 120px;	
	border-radius: 0 72px 0 0;
`

const ShapeTwo = styled.span`
	transform: rotate(45deg);
	position: absolute;
    height: 220px;
	width: 220px;
	background: #9ce1af;	
	top: -172px;
	right: 0;	
	border-radius: 32px;
`

const ShapeThree = styled.span`
	transform: rotate(45deg);
	position: absolute;
    height: 540px;
	width: 190px;
    background: linear-gradient(270deg,#54a470,#175c0f);
	top: -24px;
	right: 0;	
	border-radius: 32px;
`

const ShapeFour = styled.span`
	transform: rotate(45deg);
	position: absolute;
    height: 400px;
	width: 200px;
	background: #7bb5b9;	
	top: 420px;
	right: 50px;	
	border-radius: 60px;
`

const LoginBtn = styled.button`
	background: #fff;
	font-size: 14px;
	margin-top: 30px;
	padding: 16px 20px;
	border-radius: 15px;
	border: 1px solid #D4D3E8;
	text-transform: uppercase;
	font-weight: 700;
	display: flex;
	align-items: center;
	width: 100%;
	color: #04917a;
	box-shadow: 0px 2px 2px #04917a;
	cursor: pointer;
	transition: .2s;

    &:active, :hover, :focus {
    	border-color: #04917a;
	    outline: none;
    }

`

const LoginBtnIcon = styled.div`
	font-size: 24px;
	margin-left: auto;
	color: #04917a;
`

const Login = () => {

    const defaultValues = { entrada: '', seguranca: '' }
    const [form, setForm] = useState(defaultValues);

    const entrar = (event) => {

        event.preventDefault();

        setForm(form => ({ ...form, 'seguranca': '' }));

        login(form.entrada, form.seguranca).then(response => {
            window.location.href = response;
        });

    }

    const handleInput = (event) => {
        setForm(form => ({ ...form, [event.target.name]: event.target.value }));
    }

    return (
        <div style={pageBackground}>

            <Container>
                <LoginBox>
                    <LoginForm>

                        <LoginFormInput onSubmit={entrar}>
                            <LoginField>
                                <LoginIcon>
                                    <i className="fas fa-user"></i>
                                </LoginIcon>
                                <LoginInput required type="text" placeholder="Usuario"
                                    name="entrada" value={form.entrada} onChange={handleInput} />
                            </LoginField>
                            <LoginField>
                                <LoginIcon>
                                    <i className="login__icon fas fa-lock"></i>
                                </LoginIcon>
                                <LoginInput required type="password" placeholder="Senha"
                                    name="seguranca" value={form.seguranca} onChange={handleInput} />
                            </LoginField>
                            <LoginBtn>
                                Entrar
                                <LoginBtnIcon>
                                    <i className="fas fa-chevron-right"></i>
                                </LoginBtnIcon>
                            </LoginBtn>
                        </LoginFormInput>

                        <SystemName>
                            RPMI System
                        </SystemName>
                    </LoginForm>
                    <LoginBackground>
                        <ShapeFour />
                        <ShapeThree />
                        <ShapeTwo />
                        <ShapeOne />
                    </LoginBackground>
                </LoginBox>
            </Container>
        </div>
    )

}

export default Login;

const pageBackground = {
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    height: '100vh',
    position: 'relative',
}

import menuCreator from '../../Handlers/MenuCreatorHandler';

const itens = [
    // { label: 'Funcionário', icon: 'fa-solid fa-user', auth: '' },
    { label: 'Usuário', icon: 'fa-solid fa-users', auth: 'CADASTRO_USUARIO', url: '/cadastros/usuario' },
    { label: 'Matéria Prima', icon: 'fa-solid fa-seedling', auth: 'CADASTRO_MATERIA_PRIMA', url: '/cadastros/materiaprima' },
    { label: 'Parceiro de Negocio', icon: 'fa-solid fa-handshake', auth: 'CADASTRO_PARCEIRO_NEGOCIO', url: '/cadastros/parceirodenegocio' },
    { label: 'Produto Comercial', icon: 'fa-solid fa-boxes-packing', auth: 'CADASTRO_PRODUTO_COMERCIAL', url: '/cadastros/produtoComercial' },
    { label: 'Tipo Matéria Prima', icon: 'fa-solid fa-text-width', auth: 'CADASTRO_TIPO_MATERIA_PRIMA', url: '/cadastros/tipomateriaprima' },
    { label: 'Tipo Serviço Prestado', icon: 'fa-solid fa-person-digging', auth: 'CADASTRO_TIPO_SERVICO_PRESTADO', url: '/cadastros/tiposervicoprestado' },
    // { label: 'Veículo', icon: 'fa-solid fa-car-side', auth: '' },
]

const cadastroMenuItens = () => {
    return menuCreator(itens, 'Cadastros', 'pi pi-file-edit')
}

export default cadastroMenuItens;
import menuCreator from '../../Handlers/MenuCreatorHandler';

const itens = [
    { label: 'Cadastros', icon: 'fa-solid fa-pen-to-square', auth: 'PRODUCAO_CADASTRO', url: '/producao' },
    { label: 'Produzir', icon: 'fa-solid fa-seedling', auth: 'PRODUCAO_PRODUZIR', url: '/produzir' },
    { label: 'Processos Realizados', icon: 'fa-solid fa-play', auth: 'PRODUCAO_PRODUZIR', url: '/processo' },
]

const producaoMenuItens = () => {
    return menuCreator(itens, 'Produção', 'fa-solid fa-industry')
}

export default producaoMenuItens;
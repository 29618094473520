import React, { lazy } from "react";

import { Routes, Route } from "react-router-dom";

//Matéria Prima
const MateriaPrima = lazy(() => import('../pages/Cadastros/MateriaPrima/MateriaPrima'));
const MateriaPrimaCadastro = lazy(() => import('../pages/Cadastros/MateriaPrima/MateriaPrimaCadastro'));

// Parceiro De Negocio
const ParceiroDeNegocio = lazy(() => import('../pages/Cadastros/ParceiroDeNegocio/ParceiroDeNegocio'));
const ParceiroDeNegocioCadastro = lazy(() => import('../pages/Cadastros/ParceiroDeNegocio/ParceiroDeNegocioCadastro'));

//Produto Comercial
const ProdutoComercialList = lazy(() => import('../pages/Cadastros/ProdutoComercial/ProdutoComercialList'));
const ProdutoComercialCadastro = lazy(() => import('../pages/Cadastros/ProdutoComercial/ProdutoComercialCadastro'))

// Usuario
const UsuarioCadastro = lazy(() => import('../pages/Cadastros/Usuario/UsuarioCadastro'));
const UsuarioLista = lazy(() => import('../pages/Cadastros/Usuario/UsuarioLista'));

// Tipo Materia Prima
const TipoMateriaPrimaCadastro = lazy(() => import('../pages/Cadastros/TipoMateriaPrima/Cadastro'));
const TipoMateriaPrimaLista = lazy(() => import('../pages/Cadastros/TipoMateriaPrima/Lista'));

// Tipo Serviço Prestado
const TipoServicoPrestadoList = lazy(() => import('../pages/Cadastros/TipoServicoPrestado/TipoServicoPrestado'));


export default function CadastrosRoutes() {
    return (
        <Routes>

            {/* Tipo Materia Prima */}
            <Route path="/cadastros/tipomateriaprima/" element={<TipoMateriaPrimaLista />} />
            <Route path="/cadastros/tipomateriaprima/novo" exact element={<TipoMateriaPrimaCadastro />} />
            <Route path="/cadastros/tipomateriaprima/:id" exact element={<TipoMateriaPrimaCadastro />} />

            {/* Matéria Prima */}
            <Route path="/cadastros/materiaprima/" element={<MateriaPrima />} />
            <Route path="/cadastros/materiaprima/novo" exact element={<MateriaPrimaCadastro />} />
            <Route path="/cadastros/materiaprima/:id" exact element={<MateriaPrimaCadastro />} />

            {/* Parceiro De Negocio */}
            <Route path="/cadastros/parceirodenegocio/" exact element={<ParceiroDeNegocio />} />
            <Route path="/cadastros/parceirodenegocio/novo" exact element={<ParceiroDeNegocioCadastro />} />
            <Route path="/cadastros/parceirodenegocio/:id" exact element={<ParceiroDeNegocioCadastro />} />

            {/* Usuario */}
            <Route path="/cadastros/usuario" exact element={<UsuarioLista />} />
            <Route path="/cadastros/usuario/novo" exact element={<UsuarioCadastro />} />
            <Route path="/cadastros/usuario/:login" exact element={<UsuarioCadastro />} />

            {/*Produto Comercial */}
            <Route path="/cadastros/produtoComercial/" exact element={<ProdutoComercialList />} />
            <Route path="/cadastros/produtoComercial/novo" exact element={<ProdutoComercialCadastro />} />
            <Route path="/cadastros/produtoComercial/:id" exact element={<ProdutoComercialCadastro />} />

            {/*Tipo Serviço Prestado */}
            <Route path="/cadastros/tiposervicoprestado/" exact element={<TipoServicoPrestadoList />} />

        </Routes>
    )
}
import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";

const RecebimentoMateriaPrimaLista = lazy(() => import('../pages/Laboratorio/RecebimentoMateriaPrima/RecebimentoMateriaPrimaLista'));
const RecebimentoMateriaPrimaCadastro = lazy(() => import('../pages/Laboratorio/RecebimentoMateriaPrima/RecebimentoMateriaPrimaCadastro'));

export default function LaboratorioRoutes() {
    return (
        <Routes>
            <Route path="/laboratorio/recebimentomateriaprima" exact element={<RecebimentoMateriaPrimaLista />} />
            <Route path="/laboratorio/recebimentomateriaprima/novo" exact element={<RecebimentoMateriaPrimaCadastro />} />
            <Route path="/laboratorio/recebimentomateriaprima/:id" exact element={<RecebimentoMateriaPrimaCadastro />} />
        </Routes>
    )
}
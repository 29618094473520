import React from "react";

import { Panel } from 'primereact/panel';

const NaoEncontrado = () => {

    return (
        <React.Fragment>
            <Panel header="RPMI System">
                <div style={{ fontSize: '1.17em', paddingTop: '1.4rem' }}>
                    Pagina não encontrada
                </div>
            </Panel>
        </React.Fragment>
    )
}

export default NaoEncontrado;
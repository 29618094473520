import React from "react";

import imagemNaoPermitida from "../../static/images/not-permit.jpeg";

const SemPermissao = () => {

    return (
        <div style={{ paddingTop: '30px', textAlign: 'center', width: '100%' }}>

            <img style={imgStyle} src={imagemNaoPermitida} alt="Acesso não permitido"></img>

            <div style={titleStyle}>
                Não permitido
            </div>
            <div style={messageStyle}>
                Você não tem permissão para executar essa operação
            </div>

        </div>
    )
}

const imgStyle = {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '100%',
    borderRadius: '8px',
    maxHeight: '300px',
}

const titleStyle = {
    fontSize: '1.6em',
    paddingTop: '1.4rem',
    fontWeight: 'bold',
}

const messageStyle = {
    fontSize: '1.17em',
    paddingTop: '1rem'
}

export default SemPermissao;
import { hasAuth } from './UsuarioHandler'

const menuCreator = (menus, label, icon) => {

    const itemsPermit = [];

    menus.forEach((item) => {
        if (hasAuth(item.auth)) {
            const itemMenu = {
                label: item.label,
                icon: item.icon,
                url: item.url
            }
            itemsPermit.push(itemMenu);
        }
    });


    if (itemsPermit.length > 0) {
        const items = itemsPermit.sort((a, b) => a.label.localeCompare(b.label));
        return { label, icon, items };

    } else {
        return null;
    }

}

export default menuCreator;
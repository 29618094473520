import React, { Suspense } from "react";

import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

import Login from "../pages/Sistema/Login";
import Inicio from "../pages/Sistema/Inicio";
import NaoEncontrado from "../pages/Sistema/NaoEncontrado";
import SemPermissao from "../pages/Sistema/SemPermisao";

import CadastrosRoutes from './CadastrosRoutes';
import ProducaoRoutes from './ProducaoRoutes';
import LaboratorioRoutes from './LaboratorioRoutes';

export default function ApplicationRoutes() {
    return (
        <BrowserRouter>
            <Suspense fallback={<div>Carregando Pagina...</div>}>

                <Routes>
                    <Route path="/" element={<Login />} index />
                    <Route path="/inicio" exact element={<Inicio />} />
                    <Route path="/naoencontrado" exact element={<NaoEncontrado />} />
                    <Route path="/sempermissao" exact element={<SemPermissao />} />
                </Routes>

                <CadastrosRoutes></CadastrosRoutes>
                <ProducaoRoutes></ProducaoRoutes>
                <LaboratorioRoutes></LaboratorioRoutes>

            </Suspense>
        </BrowserRouter>
    );
}

import React from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { addLocale, locale } from 'primereact/api';
import LayoutMenu from './LayoutMenu';

const Layout = (props) => {

    const hideLayout = window.location.pathname === '/';

    addLocale('pt', {
        firstDayOfWeek: 1,
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sabado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Sx", "Sa"],
        monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: "Hoje",
        clear: "Limpar"
    });

    locale('pt')


    return (
        <React.Fragment>

            <ToastContainer position="top-center" autoClose={3000} hideProgressBar={false} newestOnTop={false}
                closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />

            {!hideLayout ?
                <LayoutMenu>
                    {props.children}
                </LayoutMenu>
                :
                <>
                    {props.children}
                </>
            }
        </React.Fragment>
    )

}

export default Layout;
export const getUsuario = () => {
    return localStorage.getItem('usuario');
}

export const hasAuth = (auth) => {
    const authoritiesStorage = localStorage.getItem('authorities');
    if (authoritiesStorage) {
        const authoritiesObject = JSON.parse(authoritiesStorage);
        const authorities = authoritiesObject.authorities;
        return authorities.includes(auth)
    }
    return false;
}
import axios from 'axios';
import qs from 'qs';
import { apiDelete } from './api'
import { toast } from 'react-toastify';
import { baseUrl } from './variables/url';

export const login = (usuario, senha) => {

    const options = {
        method: 'POST',
        baseURL: baseUrl,
        url: 'oauth/token',
        timeout: 5000,
        auth: {
            username: 'front-end',
            password: 'rpmi@qwerty'
        },
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
        },
        data: qs.stringify({
            'grant_type': 'password',
            'username': usuario,
            'password': senha,
        }),
    };

    return new Promise((resolve, reject) => {
        axios(options).then(response => {

            const authorities = { authorities: response.data.authorities };

            localStorage.setItem('token', response.data.access_token);
            localStorage.setItem('usuario', response.data.nome);
            localStorage.setItem('authorities', JSON.stringify(authorities));

            resolve('/produzir');

        }).catch(erro => {
            if (erro.response && erro.response.data.error_description === 'Bad credentials') {
                toast.error('Usuario ou Senha Invalidos');
            } else {
                toast.error('Servidor Offline');
            }
        });
    });
}

export const logout = () => {
    apiDelete('token/revoke');
    window.location.href = '/'
    localStorage.clear();
}
